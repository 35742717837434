import React from 'react';
import '../Styles/AllService.css'
import '../components/Contact';
import Contact from '../components/Contact';

const services = [
  {
    title: "Custom Software Development",
    description:
      "We provide Custom Software Development tailored to your business needs. Our team builds scalable, secure, and high-performing software that streamlines operations and drives innovation. From web and mobile applications to enterprise systems, we deliver solutions that grow with your business. Using the latest technologies and agile methods, we ensure a smooth process from concept to deployment, creating reliable and user-friendly software aligned with your goals."
  },
  {
    title: "Web Application Development",
    description:
      "We specialize in Web Application Development that delivers high-performance, scalable, and secure solutions tailored to your business needs. Our expert team builds custom web applications that enhance user experience and streamline operations, using the latest technologies to ensure fast, responsive, and reliable performance. From dynamic customer portals to complex enterprise systems, we create intuitive and engaging web applications that drive business growth and adapt as your needs evolve."
  },
  {
    title: "Mobile Application Development",
    description:
      "We offer Mobile Application Development services that create seamless, high-performing apps for both Android and iOS platforms. Our team designs and develops mobile apps tailored to your business objectives, delivering intuitive user experiences with smooth functionality. Whether you need a customer-facing app or an internal business tool, we use the latest technologies to ensure your app is responsive, secure, and scalable, helping you reach and engage your audience on the go."
  },
  {
    title: "Cloud Solutions",
    description:
      "Our Cloud Solutions empower businesses with scalable, secure, and cost-efficient cloud infrastructures. We help you leverage cloud computing to enhance flexibility, improve data management, and drive collaboration. Whether migrating existing systems or developing cloud-native applications, our expert team ensures seamless integration, high availability, and robust security. By utilizing the latest in cloud technologies, we tailor solutions that support your business growth, reduce operational costs, and enable faster innovation in a rapidly changing digital landscape."
  },
  {
    title: "Software Integration",
    description:
      "We offer seamless Software Integration services to connect your existing systems with new software solutions. Our team ensures smooth integration across platforms, enhancing workflow efficiency and data flow with minimal disruption. Whether upgrading legacy systems or adopting new technologies, we provide secure, scalable solutions that optimize performance and align with your business goals."
  }
];

const SoftwareDevelopment = () => {
  return (
    <div className="all-service-container">
      <section className="all-service-hero">
        <h1>Software Development Services</h1>
        <p>
          At <strong>Saffroot Technologies</strong>, we specialize in delivering top-notch software solutions
          tailored to meet the unique needs of businesses across various
          industries. Our team of expert developers ensures that we create
          robust, scalable, and secure applications to drive your business
          forward.
        </p>
      </section>

      <section className="all-service-services">
        {services.map((service, index) => (
          <div className="service-item" key={index}>
            <div className="service-heading-box">
              <h3>{service.title}</h3>
            </div>
            <div className="service-content-box">
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </section>
      <Contact />
    </div>
  );
};

export default SoftwareDevelopment;