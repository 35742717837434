import React from 'react';

function Services() {
  return (
    <div>
      <h1>Our Services</h1>
      <p>This is the Services page.</p>
    </div>
  );
}

export default Services;