import React, { Fragment, useEffect, useRef } from 'react';
import '../Styles/allIndustry.css';
import Contact from './Contact';

const Bfsi = () => {
  const fadeRef = useRef([]);

  useEffect(() => {
    const handleFadeIn = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        } else {
          entry.target.classList.remove('fade-in');
        }
      });
    };

    const observer = new IntersectionObserver(handleFadeIn, {
      threshold: 0.3,
    });

    fadeRef.current.forEach(fadeItem => {
      if (fadeItem) observer.observe(fadeItem);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Fragment>
    <div className="saffroot-info">
      <div className="intro">
        <h1>BFSI Services</h1>
        <p>
        At Saffroot, we deliver specialized solutions for the Banking, Financial Services, and Insurance (BFSI) industry. Our comprehensive services empower organizations to navigate the complexities of the BFSI sector by providing innovative software, recruitment of top-tier talent, and advanced training programs. Whether you're focused on enhancing customer experience, ensuring regulatory compliance, or leveraging new technologies, our solutions are designed to help you succeed.
        </p>
      </div>

      {/* Software Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>BFSI Software Solutions</h2>
          <p>In the fast-paced BFSI sector, technology is key to delivering seamless services while ensuring security, compliance, and operational efficiency. Our BFSI Software Solutions provide the technology backbone for banks, insurance companies, and financial institutions, enabling them to stay competitive in an increasingly digital landscape.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[0] = el}>
          <p>
          <strong>Core Banking Solutions (CBS):</strong> We develop and integrate core banking platforms that streamline operations, from deposits to loans and credit management. Our solutions support multi-channel banking, enabling banks to offer unified services across online, mobile, and in-branch platforms.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[1] = el}>
          <p>
          <strong>Digital Banking Platforms:</strong> We create intuitive digital banking solutions that enhance customer experience through mobile apps, internet banking, and self-service portals. These platforms offer seamless transactions, account management, and financial planning tools.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[2] = el}>
          <p>
          <strong>Risk & Compliance Management Software: </strong> Our solutions help financial institutions navigate complex regulatory requirements with automated compliance and risk management tools. We provide robust software that ensures adherence to regulations like GDPR, KYC, AML, and Basel III.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[3] = el}>
          <p>
          <strong>Payment Solutions:</strong> We design secure and scalable payment systems that enable real-time transactions, mobile payments, peer-to-peer transfers, and digital wallets. Our solutions also support cross-border payments and integrate with payment gateways.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[4] = el}>
          <p>
          <strong>Insurance Management Systems:</strong> For the insurance industry, we develop end-to-end policy management systems that automate underwriting, claims processing, customer relationship management (CRM), and actuarial analysis.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[5] = el}>
          <p>
          <strong>Wealth & Asset Management Software:</strong> Our software for financial institutions helps manage portfolios, automate trading, and provide real-time analytics and reporting for wealth management and asset management services.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[6] = el}>
          <p>
          <strong>Blockchain & Fintech Solutions:</strong> We leverage emerging technologies like blockchain to enhance security and transparency in financial transactions. Our fintech solutions help banks and financial institutions adopt decentralized finance (DeFi) systems and secure digital contracts.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[7] = el}>
          <p>
          <strong>Fraud Detection & Cybersecurity: </strong> Protect your financial services with our advanced fraud detection software and cybersecurity solutions. Using AI and machine learning, our tools detect suspicious transactions and protect your data from cyber threats.
          </p>
          </div>
        </div>
      </div>

      {/* Recruitment Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>BFSI Recruitment Solutions</h2>
          <p>Finding and retaining the right talent in the BFSI industry is essential to meeting your business goals and maintaining compliance. Our BFSI Recruitment Solutions help you attract top professionals across banking, insurance, financial services, and fintech sectors. Whether you're looking for specialists in risk management, financial analysis, IT, or compliance, we deliver the talent you need.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[8] = el}>
          <p>
          <strong>Banking & Financial Services Staffing:</strong> We provide recruitment services for key positions, including credit analysts, investment bankers, loan officers, financial advisors, and relationship managers. Our extensive network ensures access to professionals with a deep understanding of the banking and financial landscape.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[9] = el}>
          <p>
          <strong>Insurance Professionals:</strong> We recruit for specialized roles in the insurance sector, such as underwriters, claims adjusters, actuaries, and risk management experts. Whether you're hiring for general, life, or health insurance, we have the expertise to match you with qualified professionals.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[10] = el}>
          <p>
          <strong>Fintech Talent Acquisition:</strong> We specialize in recruiting talent for the rapidly growing fintech space, connecting you with experts in blockchain, mobile banking, AI, machine learning, and digital payments.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[11] = el}>
          <p>
          <strong>Risk & Compliance Specialists: </strong> We help you find experienced professionals to manage regulatory compliance, risk management, and governance. Our talent pool includes experts in anti-money laundering (AML), know your customer (KYC), data privacy, and other critical regulatory requirements.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[12] = el}>
          <p>
          <strong>Cybersecurity & IT Professionals:</strong> In the BFSI sector, protecting sensitive customer data and financial assets is paramount. We recruit top IT professionals with expertise in cybersecurity, data management, and IT infrastructure to ensure your institution's digital safety.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[13] = el}>
          <p>
          <strong>Executive Search for BFSI Leaders:</strong> From C-suite executives to senior management roles, we help you find visionary leaders who can drive your organization forward in the BFSI space.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[14] = el}>
          <p>
          <strong>Contract & Project-Based Hiring:</strong> For large-scale projects or regulatory overhauls, we provide recruitment support for temporary, contract, and project-based positions, ensuring your teams are staffed with skilled professionals.
          </p>
          </div>
        </div>
      </div>

      {/* Technology Training Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>BFSI Training Solutions</h2>
          <p>In the BFSI sector, staying competitive means keeping your teams up-to-date with the latest regulations, technologies, and industry practices. Our BFSI Training Solutions provide comprehensive programs that equip professionals with the knowledge and skills needed to thrive in this dynamic field. From compliance training to advanced fintech courses, we tailor our programs to meet your organization's specific needs.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[15] = el}>
          <p>
          <strong>Corporate Training for BFSI Companies:</strong> We offer customized training programs for financial institutions and insurance companies, covering topics like regulatory compliance, risk management, and digital banking. These programs help your teams stay informed and compliant with industry regulations.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[16] = el}>
          <p>
          <strong>Fintech & Digital Banking Training:</strong> Equip your employees with cutting-edge knowledge of fintech innovations, blockchain, mobile banking, and payment systems. Our fintech training programs focus on emerging trends and technologies that are reshaping the BFSI sector.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[17] = el}>
          <p>
          <strong>Risk Management & Compliance Training:</strong> Ensure your teams are up-to-date on the latest regulatory frameworks and risk management practices. We provide specialized training on topics such as AML, KYC, GDPR, and Basel III, helping your organization remain compliant and mitigate risks.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[18] = el}>
          <p>
          <strong>Wealth Management & Investment Training:</strong> For financial advisors and investment professionals, we offer advanced training programs focused on wealth management, portfolio optimization, and investment strategies. Our courses help your teams deliver value to clients and enhance their financial advisory services.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[19] = el}>
          <p>
          <strong>Cybersecurity Training for BFSI:</strong> In today’s digital-first environment, cybersecurity is critical to protecting customer data and financial transactions. Our training programs ensure your IT teams are prepared to safeguard your systems against cyber threats and fraud.
          </p>
          </div>
        </div>
      </div>
    </div>
    <div className='more-space'>
    <Contact />
    </div>
    </Fragment>
    
    
  );
};

export default Bfsi;
