import React, { Fragment, useEffect, useRef } from 'react';
import '../Styles/allIndustry.css';
import Contact from './Contact';

const InformationTechnology = () => {
  const fadeRef = useRef([]);

  useEffect(() => {
    const handleFadeIn = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        } else {
          entry.target.classList.remove('fade-in');
        }
      });
    };

    const observer = new IntersectionObserver(handleFadeIn, {
      threshold: 0.3,
    });

    fadeRef.current.forEach(fadeItem => {
      if (fadeItem) observer.observe(fadeItem);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Fragment>
    <div className="saffroot-info">
      <div className="intro">
        <h1>Information Technology Services</h1>
        <p>
        At Saffroot, we are committed to delivering cutting-edge technology solutions to help businesses stay ahead in the digital age. Whether you're looking for robust software solutions, recruitment of top IT talent, or advanced technology training, we’ve got you covered.
        </p>
      </div>

      {/* Software Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>IT Software Solutions</h2>
          <p>Our Software Solutions are designed to transform your business through innovative and custom-tailored software development. Whether you're a startup, small business, or an established enterprise, we provide scalable and efficient solutions to meet your unique needs. Our experienced team of developers, engineers, and designers collaborate with you from the ideation stage to the final deployment, ensuring a smooth, efficient process.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[0] = el}>
          <p>
          <strong>Custom Software Development:</strong> We develop tailor-made software to suit your business processes, ensuring increased efficiency and productivity.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[1] = el}>
          <p>
          <strong>Web Development:</strong> Our team creates visually appealing, responsive, and high-performing websites that leave a lasting impression on your users.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[2] = el}>
          <p>
          <strong>Mobile App Development:</strong> From concept to launch, we build mobile apps for Android and iOS that offer seamless user experiences.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[3] = el}>
          <p>
          <strong>Cloud Solutions:</strong> Leverage the power of cloud technology for enhanced security, flexibility, and scalability. We help businesses migrate, integrate, and manage cloud platforms efficiently.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[4] = el}>
          <p>
          <strong>AI & ML Solutions:</strong> Our expertise in Artificial Intelligence and Machine Learning helps businesses innovate by integrating intelligent systems into their workflows, from chatbots to predictive analytics.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[5] = el}>
          <p>
          <strong>Software Maintenance & Support:</strong> We ensure your software stays up-to-date and secure with ongoing maintenance, upgrades, and technical support.
          </p>
          </div>
        </div>
      </div>

      {/* Recruitment Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>IT Recruitment Solutions</h2>
          <p>Finding and hiring the right talent is crucial for any business’s success. Our Recruitment Solutions are tailored to help you attract, assess, and hire top IT professionals who match your company’s needs. With an extensive network of professionals across the technology spectrum, we make the recruitment process seamless and effective.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[6] = el}>
          <p>
          <strong>IT Staffing & Placement:</strong> We provide permanent, temporary, and contract-based IT staffing solutions to help you quickly fill positions with qualified candidates.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[7] = el}>
          <p>
          <strong>Executive Search:</strong> Finding C-level and senior management talent for technology roles is our specialty. We carefully match leaders with the skills and vision your business needs to thrive.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[8] = el}>
          <p>
          <strong>Skill-Based Recruitment:</strong> We focus on identifying candidates with specific technical skills like programming languages (Java, Python, etc.), cloud technologies, AI/ML, data science, cybersecurity, and more.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[9] = el}>
          <p>
          <strong>Project-Based Hiring:</strong> Whether you're working on a short-term or long-term IT project, we help you find the right talent to fit your project needs, ensuring success.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[10] = el}>
          <p>
          <strong>Onboarding & Retention Support:</strong> We support your onboarding process to ensure smooth integration of new hires into your team, and help implement retention strategies for top performers.
          </p>
          </div>
        </div>
      </div>

      {/* Technology Training Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>IT Technology Training Solutions</h2>
          <p>In the fast-paced tech world, staying up-to-date with the latest technologies is key to maintaining a competitive edge. Our Technology Training Solutions provide comprehensive training programs that equip individuals and teams with the skills they need to succeed in today’s rapidly evolving IT landscape. We offer both corporate training for businesses and skill development courses for individual learners.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[11] = el}>
          <p>
          <strong>Corporate Training Programs:</strong> We offer customized training programs for businesses looking to upskill their workforce in areas like cloud computing, AI/ML, data science, DevOps, programming languages, and more. We work with your team to develop a curriculum that meets your unique training objectives and aligns with your business goals.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[12] = el}>
          <p>
          <strong>Online & In-Person Courses:</strong> Our flexible training solutions are available online, in-person, or through hybrid models, making it easy for individuals or teams to participate from anywhere in the world.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[13] = el}>
          <p>
          <strong>Advanced Technology Training:</strong> Stay ahead of the curve with specialized training in cutting-edge technologies like artificial intelligence, blockchain, machine learning, and data analytics.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[14] = el}>
          <p>
          <strong>Programming Bootcamps:</strong> We offer intensive bootcamps designed to provide hands-on experience in key programming languages (e.g., Python, JavaScript, C++, etc.) and development environments.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[15] = el}>
          <p>
          <strong>Certification Preparation:</strong> Get your team certified in key IT areas like AWS, Google Cloud, Microsoft Azure, and more. We provide training to prepare learners for the industry’s most sought-after certifications.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[16] = el}>
          <p>
          <strong>Ongoing Support & Mentorship:</strong> After the training, we offer ongoing support and mentorship to ensure your team can effectively apply the skills they've learned.
          </p>
          </div>
        </div>
      </div>
    </div>
    <div className='more-space'>
    <Contact />
    </div>
    </Fragment>
    
    
  );
};

export default InformationTechnology;
