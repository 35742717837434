import React, { useState } from 'react';
import '../Styles/JobSeekerContact.css';

const JobSeekerContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
    totalExperience: '',
    relevantExperience: '',
    topSkills: '',
    currentDesignation: '',
    currentCompany: '',
    servingNotice: '',
    lastWorkingDay: '',
    officialNoticePeriod: '',
    preferredJobRole: '',
    currentLocation: '',
    preferredLocation: '',
  });
  const [resume, setResume] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const validate = () => {
    let validationErrors = {};
    if (!formData.name.trim()) validationErrors.name = 'Name is required';
    if (!formData.email.trim()) validationErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) validationErrors.email = 'Email address is invalid';
    if (!formData.phone.trim()) validationErrors.phone = 'Phone number is required';
    if (!formData.totalExperience.trim()) validationErrors.totalExperience = 'Total experience is required';
    if (!formData.relevantExperience.trim()) validationErrors.relevantExperience = 'Relevant experience is required';
    if (!formData.topSkills.trim()) validationErrors.topSkills = 'Top skills are required';
    if (!formData.currentDesignation.trim()) validationErrors.currentDesignation = 'Current designation is required';
    if (!formData.currentCompany.trim()) validationErrors.currentCompany = 'Current company is required';
    if (!formData.servingNotice) validationErrors.servingNotice = 'Please select if you are currently serving notice';
    if (formData.servingNotice === 'yes' && !formData.lastWorkingDay.trim()) validationErrors.lastWorkingDay = 'Last working day is required';
    if (formData.servingNotice === 'no' && !formData.officialNoticePeriod.trim()) validationErrors.officialNoticePeriod = 'Official notice period is required';
    if (!formData.message.trim()) validationErrors.message = 'Message is required';
    if (!formData.preferredJobRole.trim()) validationErrors.preferredJobRole = 'Preferred job role is required';
    if (!formData.currentLocation.trim()) validationErrors.currentLocation = 'Current location is required';
    if (!formData.preferredLocation.trim()) validationErrors.preferredLocation = 'Preferred location is required';
    if (!resume) validationErrors.resume = 'Resume is required';
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch('https://saffroot.com/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

  
        if (response.ok) {
          setSubmitted(true);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
            totalExperience: '',
            relevantExperience: '',
            topSkills: '',
            currentDesignation: '',
            currentCompany: '',
            servingNotice: '',
            lastWorkingDay: '',
            officialNoticePeriod: '',
            preferredJobRole: '',
            currentLocation: '',
            preferredLocation: '',
          });
          setResume(null);
          setErrors({});
        } else {
          throw new Error('Failed to send message');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setErrors(validationErrors);
    }
  };


  return (
    <div className="contact-form-container">
      <h2>Explore Career Opportunities – Apply Now</h2>
      {submitted && <p className="success-message">Thank you! We’ll be in touch soon.</p>}
      <form onSubmit={handleSubmit}>
      <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your name"
          />
          {errors.name && <p className="error">{errors.name}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your email"
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your phone number"
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="totalExperience">Total Experience (years)</label>
          <input
            type="text"
            name="totalExperience"
            value={formData.totalExperience}
            onChange={handleChange}
            placeholder="Total experience in years"
          />
          {errors.totalExperience && <p className="error">{errors.totalExperience}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="relevantExperience">Relevant Experience (years)</label>
          <input
            type="text"
            name="relevantExperience"
            value={formData.relevantExperience}
            onChange={handleChange}
            placeholder="Relevant experience in years"
          />
          {errors.relevantExperience && <p className="error">{errors.relevantExperience}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="topSkills">Top Skills</label>
          <input
            type="text"
            name="topSkills"
            value={formData.topSkills}
            onChange={handleChange}
            placeholder="Your top skills"
          />
          {errors.topSkills && <p className="error">{errors.topSkills}</p>}
        </div>
        {/* Existing fields for name, email, etc. */}
        <div className="form-group">
          <label htmlFor="currentDesignation">Current Designation</label>
          <input
            type="text"
            name="currentDesignation"
            value={formData.currentDesignation}
            onChange={handleChange}
            placeholder="Your current designation"
          />
          {errors.currentDesignation && <p className="error">{errors.currentDesignation}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="preferredJobRole">Preferred Job Role</label>
          <input
            type="text"
            name="preferredJobRole"
            value={formData.preferredJobRole}
            onChange={handleChange}
            placeholder="Preferred job role"
          />
          {errors.preferredJobRole && <p className="error">{errors.preferredJobRole}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="currentCompany">Current Company</label>
          <input
            type="text"
            name="currentCompany"
            value={formData.currentCompany}
            onChange={handleChange}
            placeholder="Your current company"
          />
          {errors.currentCompany && <p className="error">{errors.currentCompany}</p>}
        </div>
        <div className="form-group">
        <label htmlFor="currentLocation">Current Location (City)</label>
        <input
          type="text"
          name="currentLocation"
          value={formData.currentLocation}
          onChange={handleChange}
          placeholder="Your current city"
        />
        {errors.currentLocation && <p className="error">{errors.currentLocation}</p>}
      </div>
      <div className="form-group">
        <label htmlFor="preferredLocation">Preferred Location (City)</label>
        <input
          type="text"
          name="preferredLocation"
          value={formData.preferredLocation}
          onChange={handleChange}
          placeholder="Your preferred city"
        />
        {errors.preferredLocation && <p className="error">{errors.preferredLocation}</p>}
      </div>
        <div className="form-group">
        <label htmlFor="resume">Resume</label>
        <input type="file" name="resume" onChange={handleFileChange} />
        {errors.resume && <p className="error">{errors.resume}</p>}
      </div>
        <div className="form-group">
          <label htmlFor="servingNotice">Are you serving notice?</label>
          <select
            name="servingNotice"
            value={formData.servingNotice}
            onChange={handleChange}
            required
          >
            <option value="">Select an option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {errors.servingNotice && <p className="error">{errors.servingNotice}</p>}
        </div>
        {formData.servingNotice === 'yes' && (
          <div className="form-group">
            <label htmlFor="lastWorkingDay">Last Working Day</label>
            <input
              type="date"
              name="lastWorkingDay"
              value={formData.lastWorkingDay}
              onChange={handleChange}
            />
            {errors.lastWorkingDay && <p className="error">{errors.lastWorkingDay}</p>}
          </div>
        )}
        {formData.servingNotice === 'no' && (
          <div className="form-group">
            <label htmlFor="officialNoticePeriod">Official Notice Period (in days)</label>
            <input
              type="number"
              name="officialNoticePeriod"
              value={formData.officialNoticePeriod}
              onChange={handleChange}
              placeholder="Your official notice period in days"
            />
            {errors.officialNoticePeriod && <p className="error">{errors.officialNoticePeriod}</p>}
          </div>
          
        )}
      <div className="form-group">
      <label htmlFor="message">Message</label>
      <textarea
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder="Your message"
        rows="4"
      />
      {errors.message && <p className="error">{errors.message}</p>}
    </div>
    
        {/* Other existing fields like resume upload */}
        <button type="submit" className='contact-button'>Submit</button>
      </form>
    </div>
  );
};

export default JobSeekerContact;
