import React from 'react';
import '../Styles/About.css'; // Enhanced CSS for styling

const AboutUs = () => {
  // Array of testimonials from senior members
  const testimonials = [
    {
      name: 'John Smith',
      position: 'CEO',
      quote: 'At Saffroot, we believe in innovation, teamwork, and excellence in everything we do.',
    },
    {
      name: 'Jane Doe',
      position: 'CTO',
      quote: 'Our culture is built on mutual respect and continuous learning.',
    },
  ];

  // YouTube video IDs to embed
  const videoIds = ['videoId1', 'videoId2', 'videoId3']; // Replace with actual YouTube video IDs

  return (
    <div className="about-us-container">
      {/* About Saffroot section */}
      <section className="about-saffroot">
        <h2>About Saffroot</h2>
        <p>
          Saffroot is a leader in software development, tech training, and recruitment services. With a commitment to excellence and innovation, we empower businesses by providing tailored software solutions, advanced corporate training, and strategic workforce support. Our team of experts is driven by passion, ensuring that every project is handled with precision, creativity, and a client-centric approach.
        </p>
        <p>
          We take pride in creating a collaborative work environment where continuous learning and mutual respect are at the core of our values. By staying ahead of the curve with the latest technologies, we strive to make a meaningful impact in the industry and help our partners achieve sustained success.
        </p>
      </section>

      {/* Why Choose Saffroot section */}
      <section className="why-choose-saffroot">
        <h2>Why Choose Saffroot?</h2>
        <p>
          Choosing Saffroot means partnering with a team dedicated to delivering innovative solutions, comprehensive training, and expert recruitment services. Our commitment to quality and continuous improvement ensures we exceed client expectations and drive mutual growth.
        </p>
        <ul>
          <li><strong>Expert Team:</strong> Our professionals bring years of industry experience and a forward-thinking approach.</li>
          <li><strong>Innovative Solutions:</strong> We leverage the latest technologies and best practices to provide effective solutions.</li>
          <li><strong>Comprehensive Training:</strong> We offer training in advanced tech skills to keep your teams at the forefront.</li>
          <li><strong>Client-Centric Approach:</strong> Your needs shape our strategies, guaranteeing tailored solutions.</li>
        </ul>
      </section>

      {/* Work environment section */}
      <section className="work-environment">
        <h2>Our Culture & Work Environment</h2>
        <p>
          At Saffroot, we nurture a culture that values collaboration, creativity, and growth. Our work environment is designed to inspire innovation and foster open communication, ensuring every team member feels heard and empowered. We celebrate diversity, encourage professional development, and prioritize work-life balance, making Saffroot not just a workplace but a community.
        </p>
      </section>

      {/* What our senior people say section */}
      <section className="testimonials">
        <h2>What Our Leaders Say</h2>
        <div className="testimonials-list">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <div className="quote-symbol">“</div>
              <p className="quote-text">{testimonial.quote}</p>
              <div className="author-details">
                <h3>{testimonial.name}</h3>
                <h4>{testimonial.position}</h4>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* YouTube vlogs section */}
      <section className="culture-videos">
        <h2>Explore Our Vlogs</h2>
        <p>Get a glimpse of our vibrant company culture through these vlogs.</p>
        <div className="videos-list">
          {videoIds.map((id, index) => (
            <iframe
              key={index}
              className="video-frame"
              src={`https://www.youtube.com/embed/${id}`}
              title={`Video ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
