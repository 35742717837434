import React, { useState, useEffect, useRef } from 'react';
import '../Styles/Technologies.css';

function Technologies() {
  const [activeTab, setActiveTab] = useState('Programming_Languages');
  const [visibleTabs, setVisibleTabs] = useState([]);
  const [activeCategory, setActiveCategory] = useState('Software Technologies'); // Track active main category
  const headingRef = useRef(null);
  const techContainerRef = useRef(null);
  const buttonContainerRef = useRef(null);
  const [isHeadingVisible, setIsHeadingVisible] = useState(false);
  const [isTechContainerVisible, setIsTechContainerVisible] = useState(false);
  const [isbuttonContainerVisible, setIsbuttonContainerVisible] = useState(false);

  const categories = {
    Programming_Languages: ['Java', 'Python', 'C#', 'VB', 'Golang', 'C', 'C++', 'JavaScript', 'Scala', 'Groovy', 'Swift', 'Perl', 'Ruby', 'Objective-C', 'PHP', 'SQL', 'COBOL', 'Kotlin', 'Embedded C/C++', 'PLSQL'],
    Frameworks_Libraries: ['Spring', 'Spring Boot', '.Net', 'Angular', 'React', 'Node', 'Django', 'Flask', 'Spark', 'Ruby on Rails', 'Vue', 'Express', 'Struts', 'Hibernate'],
    Data_Engineering: ['Spark', 'Hadoop', 'Kafka', 'Snowflake', 'Redshift', 'Tableau', 'Hive', 'BigQuery', 'Power BI', 'Power Platform', 'Talend', 'Cloudera', 'Nifi', 'Presto', 'Airflow'],
    ERP: ['SAP', 'Oracle', 'MS Dynamics', 'Peoplesoft', 'Workday'],
    Business_Intelligence: ['Tableau', 'Cognos', 'Pentaho', 'Business Objects', 'Qlik', 'Power BI', 'MicroStrategy'],
    Cloud: ['AWS', 'Azure', 'GCP', 'IBM Cloud', 'Oracle Cloud'],
    Databases: ['Oracle', 'My SQL', 'MS SQL', 'Mongo DB', 'PostgreSQL', 'Cassandra', 'DB2', 'Dynamo', 'HBase', 'Redis'],
    DevOps: ['Docker', 'Kubernetes', 'Ansible', 'Jenkins', 'Puppet', 'Chef', 'Terraform', 'Splunk', 'Bamboo', 'Maven', 'Git', 'Nagios', 'Gradle'],
    Microcontrollers__Microprocessors: ['Arduino (Atmel AVR, ARM Cortex-M)','Raspberry Pi (Broadcom BCM283x)', 'ESP8266/ ESP32', 'STM32', 'Intel Galileo/ Edison', 'BeagleBone', 'PIC Microcontrollers'],
    RTOS: ['FreeRTOS', 'ThreadX', 'Zephyr', 'VxWorks', 'uC/OS'],
    Embedded_Development: ['GNU Toolchain (GCC, GDB)', 'Keil MDK (ARM)', 'IAR Embedded Workbench', 'MPLAB X IDE (Microchip)', 'Eclipse Embedded CDT'],
    Automotive_RTOS: ['AUTOSAR', 'QNX', 'Integrity RTOS'],
    IOT_RTOS: ['Amazon', 'FreeRTOS', 'Azure RTOS', 'MbedOS'],
    Embedded_Networking: ['Ethernet', 'CAN bus (Controller Area Network)', 'Modbus', 'MQTT', 'CoAP'],
    HDL_Security_Firmware: ['Secure Boot', 'Firmware Encryption', 'Hardware Security Modules (HSM)', 'Trusted Platform Modules (TPM)', 'Verilog', 'VHDL', 'low-level software'],
    Automotive_Systems: ['Device Driver', 'ECUs', 'TCMs', 'ABS', 'Infotainment Systems', 'ADAS', 'BCMs', 'Connectivity', 'QNX', 'AGL', 'Apple Carplay', 'Android Auto', 'HMI'],
    Design: ['CAD Tools', 'HDL (VHDL, Verilog)', 'RTL Design Tools', 'Schematic Capture Tools'],
    Simulation: ['Logic Simulators (ModelSim, VCS, NC-Sim)', 'Analog Simulators (SPICE)', 'Mixed-Signal Simulators'],
    Synthesis: ['Synopsys Design Compiler', 'Cadence Genus'], 
    Verification: ['Formal Verification Tools', 'Functional Verification Tools (UVM)', 'Coverage Analysis Tools'],
    Physical_Design: ['Place and Route Tools (Cadence Innovus, Synopsys IC Compiler)', 'DRC Tools', 'LVS Tools'],
    Fabrication: ['Etching', 'Deposition', 'CMP', 'Photo- lithography'],
    Testing: ['Automated Test Equipment (ATE)', 'Burn-In Testing'],
    Software_Tools:['Cadence', 'Synopsys', 'Mentor Graphics'],
    Libraries_IPs: ['Standard Cell Libraries', 'IP Cores'],
    Machine_Learning_Frameworks:['TensorFlow', 'PyTorch', 'scikit-learn', 'Keras', 'MXNet', 'Caffe', 'Theano'],
    Natural_Language_Processing: ['NLTK', 'spaCy', 'Gensim', 'Transformers', 'AllenNLP', 'CoreNLP'],
    Computer_Vision:['OpenCV', 'DLib', 'SimpleCV'],
    AI_Development_Platforms: ['Google AI Platform', 'Microsoft Azure Machine Learning', 'IBM Watson Studio', 'Amazon SageMaker'],
    Data_Preprocessing: ['Pandas', 'NumPy', 'scikit-learn', 'Featuretools'],
    Distributed_Computing: ['Apache Spark MLlib', 'Dask', 'Ray'],
    AR: ['Unity', 'Unreal Engine', 'ARKit', 'ARCore', 'Adobe Aero'],
    AI_Tools: ['TensorFlow', 'PyTorch', 'scikit-learn', 'Keras', 'IBM Watson Studio', 'H2O.ai', 'MATLAB', 'DataRobot', 'Google Cloud AI Platform'],
  };
  const techItems = categories[activeTab] || [];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleButtonClick = (label) => {
    setActiveCategory(label); // Track which category button is active
    switch (label) {
      case 'Software Technologies':
        setVisibleTabs(['Programming_Languages', 'Frameworks_Libraries', 'Data_Engineering', 'ERP', 'Business_Intelligence', 'Cloud', 'Databases', 'DevOps']);
        setActiveTab('Programming_Languages');
        break;
      case 'Embedded':
        setVisibleTabs(['Microcontrollers__Microprocessors', 'RTOS', 'Embedded_Development', 'Automotive_RTOS', 'IOT_RTOS', 'Embedded_Networking', 'HDL_Security_Firmware', 'Automotive_Systems' ]);
        setActiveTab('Microcontrollers__Microprocessors');
        break;
      case 'VLSI':
        setVisibleTabs(['Design', 'Simulation', 'Synthesis', 'Verification', 'Physical_Design', 'Fabrication', 'Testing', 'Software_Tools', 'Libraries_IPs']);
        setActiveTab('Design');
        break;
      case 'AI/ML':
        setVisibleTabs(['Machine_Learning_Frameworks', 'Natural_Language_Processing', 'Computer_Vision', 'AI_Development_Platforms', 'Data_Preprocessing', 'Distributed_Computing', 'AR', 'AI_Tools' ]);
        setActiveTab('Machine_Learning_Frameworks');
        break;
      default:
        setVisibleTabs([]);
        setActiveTab('');
    }
  };

  useEffect(() => {
    // Automatically set visible tabs for the default category (Software Technologies) on initial render
    setVisibleTabs(['Programming_Languages', 'Frameworks_Libraries', 'Data_Engineering', 'ERP', 'Business_Intelligence', 'Cloud', 'Databases', 'DevOps']);
  }, []);

  useEffect(() => {
    const handleIntersection = (entries, observer, setState) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setState(true);
        } else {
          setState(false);
        }
      });
    };

    const headingObserver = new IntersectionObserver(
      (entries, observer) => handleIntersection(entries, observer, setIsHeadingVisible),
      { threshold: 0.5 }
    );

    const techContainerObserver = new IntersectionObserver(
      (entries, observer) => handleIntersection(entries, observer, setIsTechContainerVisible),
      { threshold: 0.5 }
    );

    const buttonContainerObserver = new IntersectionObserver(
      (entries, observer) => handleIntersection(entries, observer, setIsbuttonContainerVisible),
      { threshold: 0.5 }
    );

    if (headingRef.current) {
      headingObserver.observe(headingRef.current);
    }

    if (techContainerRef.current) {
      techContainerObserver.observe(techContainerRef.current);
    }

    if (buttonContainerRef.current) {
      buttonContainerObserver.observe(buttonContainerRef.current);
    }

    return () => {
      if (headingRef.current) {
        headingObserver.unobserve(headingRef.current);
      }
      if (techContainerRef.current) {
        techContainerObserver.unobserve(techContainerRef.current);
      }
      if (buttonContainerRef.current) {
        buttonContainerObserver.unobserve(buttonContainerRef.current);
      }
    };
  }, []);

  return (
    <div className="technologies-section part">
      <h2 ref={headingRef} className={`heading ${isHeadingVisible ? 'animate-heading' : ''}`}>
        Technologies We Serve
      </h2>
      <div className={`button-container ${isbuttonContainerVisible ? 'animate-button-container' : ''}`} ref={buttonContainerRef}>
        <button className={activeCategory === 'Software Technologies' ? 'active-main' : ''} onClick={() => handleButtonClick('Software Technologies')}>
          Software Technologies
        </button>
        <button className={activeCategory === 'Embedded' ? 'active-main' : ''} onClick={() => handleButtonClick('Embedded')}>
          Embedded technologies
        </button>
        <button className={activeCategory === 'VLSI' ? 'active-main' : ''} onClick={() => handleButtonClick('VLSI')}>
          VLSI / Semiconductor
        </button>
        <button className={activeCategory === 'AI/ML' ? 'active-main' : ''} onClick={() => handleButtonClick('AI/ML')}>
          AI & ML
        </button>
      </div>
      <div className={`tech-container ${isTechContainerVisible ? 'animate-tech-container' : ''}`} ref={techContainerRef}>
        <div className="tech-tabs">
          {visibleTabs.map((category) => {
            const underscoreCount = (category.match(/_/g) || []).length;

            let formattedCategory = category;
            if (underscoreCount === 1) {
              formattedCategory = category.replace('_', ' ');
            } else if (underscoreCount === 2) {
              const needsSpace = category === 'Machine_Learning_Frameworks' || category === 'HDL_Security_Firmware' || category === 'Natural_Language_Processing' || category === 'AI_Development_Platforms';
              if (needsSpace) {
                formattedCategory = category.replace('_', ' ').replace('_', ' ');
              } else {
                formattedCategory = category.replace('_', ' ').replace('_', ' & ');
              }
            }

            return (
              <button
                key={category}
                className={`tab-button ${activeTab === category ? 'active' : ''}`}
                onClick={() => handleTabClick(category)}
              >
                {formattedCategory}
              </button>
            );
          })}
        </div>
        <div className="tech-content">
          {techItems.map((tech, index) => (
            <div key={index} className="tech-item">
              {tech}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Technologies;
