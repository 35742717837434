import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../Styles/Ribbon.css';
import homeIcon from '../images/Saffroot Name Only.png'; // Adjust the path as necessary

function Ribbon() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="ribbon">
        <div className="logo-container">
          <NavLink exact to="/" className="home-link">
            <img src={homeIcon} alt="Home" className="home-icon" />
          </NavLink>
          <button className="hamburger" onClick={toggleMenu}>
            ☰
          </button>
        </div>
        <ul className={`menu ${isOpen ? 'open' : ''}`}>
          <li>
            <NavLink to="/application-development" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Software Solutions
            </NavLink>
          </li>
          <li>
            <NavLink to="/recruitment-solutions" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Recruitment Solutions
            </NavLink>
          </li>
          <li>
            <NavLink to="/training-section" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Tech Empowerment
            </NavLink>
          </li>
          <li>
            <NavLink to="/industries-ribbon" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Industries
            </NavLink>
          </li>
          <li>
            <NavLink to="/About" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/client-contact" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/career" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Career At Saffroot
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Ribbon;
