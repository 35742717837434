import React, { useEffect, useRef } from 'react';
import '../Styles/AllService.css'
import Contact from '../components/Contact';

const services = [
  {
    title: "Information Technology (IT)",
    description:
      "We provide recruitment services across all core technologies within the IT domain. This includes areas such as software development, system architecture, cloud computing, cybersecurity, data management, and cutting-edge fields like artificial intelligence (AI) and machine learning (ML). Our expertise spans a broad range of IT roles, from technical specialists in AI algorithms to IT project managers overseeing large-scale digital transformations."
  },
  {
    title: "Semiconductor & VLSI",
    description:
      "In the Semiconductor and VLSI domain, we cater to roles involved in the design, development, and manufacturing of microchips and integrated circuits. This includes positions focusing on chip design, embedded systems, and the application of AI/ML for enhancing chip performance and capabilities."
  },
  {
    title: "Embedded Systems",
    description:
      "Embedded systems play a crucial role in a variety of applications, integrating hardware and software to perform dedicated functions within larger mechanical or electrical systems. These systems are often found in consumer electronics, automotive, and industrial automation, where they execute specific tasks with real-time computing requirements. Our focus includes sourcing candidates skilled in designing and developing these systems, ensuring they can optimize performance and reliability in resource-constrained environments."
  },
  {
    title: "Automotive Industry",
    description:
      "The automotive industry is rapidly transforming with advancements in electric vehicles, autonomous driving, and smart technologies. We specialize in recruiting talent for roles in automotive engineering, software development for vehicle systems, and R&D positions focusing on new technologies that drive innovation and safety in transportation."
  },
  {
    title: "Banking, Financial Services, and Insurance (BFSI)",
    description:
      "In the BFSI sector, we provide recruitment services that address the complexities of financial technology, regulatory compliance, and risk management. Our focus includes sourcing candidates with expertise in fintech development, cybersecurity, data analysis, and financial modeling, ensuring organizations can navigate the evolving landscape of finance and insurance."
  },
  {
    title: "Telecommunications",
    description:
      "In the telecom sector, we provide recruitment services for roles that support the development and maintenance of communication networks and technologies. Our focus includes sourcing candidates with expertise in network engineering, telecommunications software, and 5G technologies, ensuring organizations stay at the forefront of connectivity advancements."
  },
  {
    title: "Healthcare",
    description:
      "The healthcare industry demands skilled professionals who can navigate complex regulations, improve patient outcomes, and drive technological innovation. We specialize in recruiting healthcare professionals, including medical technologists, IT specialists in health informatics, and project managers for healthcare initiatives."
  },
  {
    title: "Pharmaceuticals",
    description:
      "The pharmaceuticals industry requires specialized talent for drug development, regulatory compliance, and clinical research. We focus on recruiting scientists, researchers, and project managers who can drive innovation and ensure that organizations meet stringent regulatory standards."
  },
  {
    title: "Energy",
    description:
      "In the energy sector, we focus on sourcing candidates who are experts in renewable energy, power generation, and energy management systems. Our recruitment services support roles in energy engineering, sustainability consulting, and project management, ensuring organizations can meet the challenges of a rapidly changing energy landscape."
  },
  {
    title: "Manufacturing",
    description:
      "The manufacturing industry is evolving through automation, robotics, and Industry 4.0 technologies. We specialize in finding candidates with skills in process engineering, quality control, and supply chain management, helping organizations enhance efficiency and drive innovation in production."
  }
];

const RecruitmentSolutions = () => {
  const fadeRef = useRef([]);

  useEffect(() => {
    const handleFadeIn = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        } else {
          entry.target.classList.remove('fade-in');
        }
      });
    };

    const observer = new IntersectionObserver(handleFadeIn, {
      threshold: 0.3,
    });

    fadeRef.current.forEach(fadeItem => {
      if (fadeItem) observer.observe(fadeItem);
    });

    return () => observer.disconnect();
  }, []);
  
  return (
    <div className="all-service-container">
      <section className="all-service-hero">
        <h1>Expert Recruitment Solutions</h1>
        <p>
          At <strong>Saffroot Technologies</strong>, we specialize in providing tailored recruitment services across various sectors, ensuring that you get the right talent to drive your business forward. Whether you're hiring for permanent positions, contract roles, or executive searches, our recruitment solutions are designed to meet the unique needs of your organization.
        </p>
      </section>

      <section className="all-service-details">
        <div className="all-service-details-box">
          <h2>Why Choose Saffroot Recruitment Solutions?</h2>
    
          <div className="fade-div" ref={el => fadeRef.current[0] = el}> <p>
          <strong>Expertise Across Key Sectors:</strong> We specialize in recruiting for IT, Semiconductor, and Embedded Systems, along with industries such as Automotive, BFSI, Telecommunications, and Healthcare. We provide industry-specific knowledge to match the right candidates with your roles.
        </p></div>
          <div className="fade-div" ref={el => fadeRef.current[1] = el}><p>
          <strong>Comprehensive Candidate Screening:</strong> We thoroughly screen candidates based on technical, operational, and leadership competencies to ensure you only meet with the most qualified professionals.
        </p></div>
          <div className="fade-div" ref={el => fadeRef.current[2] = el}><p>
          <strong>Technology-Focused Hiring:</strong> With deep expertise in technologies like cloud computing, cybersecurity, AI/ML, and embedded systems, we provide access to professionals with cutting-edge knowledge in these areas.
        </p></div>
          <div className="fade-div" ref={el => fadeRef.current[3] = el}><p>
          <strong>Tailored Solutions:</strong> Whether you need permanent staff, contract workers, or project-based hires, we offer flexible solutions that fit your needs.
        </p></div>
        </div>

        <div className="all-service-details-box">
          <h2>Our Recruitment Process</h2>
          <div className="fade-div" ref={el => fadeRef.current[4] = el}>
          <p>
            <strong>Client Consultation:</strong> We begin by understanding your business, culture, and hiring needs to ensure we provide the best candidates for your roles.
          </p>
          </div>
          <div className="fade-div" ref={el => fadeRef.current[5] = el}>
          <p>
          <strong>Sourcing and Screening:</strong> Using our network of professionals and advanced recruitment technology, we source and screen top candidates efficiently.
        </p>
          </div>
         
          <div className="fade-div" ref={el => fadeRef.current[6] = el}>
          <p>
          <strong>Interview Coordination:</strong> We manage interview scheduling, gathering feedback from both parties to ensure smooth processes.
        </p>
          </div>
          <div className="fade-div" ref={el => fadeRef.current[7] = el}>
           <p>
            <strong>Offer and Onboarding:</strong> We assist in offer negotiations and provide onboarding support to guarantee a seamless transition for new hires.
          </p>
          </div>
         
        </div>
      </section>

      <section className="all-service-services">
        <h2>Recruitment Solutions We Offer</h2>
        {services.map((service, index) => (
          <div className="service-item" key={index}>
            <div className="service-heading-box">
              <h3>{service.title}</h3>
            </div>
            <div className="service-content-box">
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </section>

      <Contact />
    </div>
  );
};

export default RecruitmentSolutions;
