import React, { Fragment, useEffect, useRef } from 'react';
import '../Styles/allIndustry.css';
import Contact from './Contact';

const Telecommunication = () => {
  const fadeRef = useRef([]);

  useEffect(() => {
    const handleFadeIn = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        } else {
          entry.target.classList.remove('fade-in');
        }
      });
    };

    const observer = new IntersectionObserver(handleFadeIn, {
      threshold: 0.3,
    });

    fadeRef.current.forEach(fadeItem => {
      if (fadeItem) observer.observe(fadeItem);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Fragment>
    <div className="saffroot-info">
      <div className="intro">
        <h1>Telecom Services</h1>
        <p>
        At Saffroot, we provide comprehensive telecom solutions designed to enhance connectivity, optimize performance, and drive digital transformation in the telecommunications sector. From cutting-edge software solutions to recruitment of skilled professionals and advanced training programs, we help telecom companies stay ahead in an increasingly competitive landscape.
        </p>
      </div>

      {/* Software Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>Telecom Software Solutions</h2>
          <p>Our Telecom Software Solutions are crafted to meet the unique challenges faced by telecom operators, service providers, and vendors. With a focus on delivering scalable, secure, and high-performance software, we help you streamline operations, improve customer experience, and drive innovation in your services.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[0] = el}>
          <p>
          <strong>Network Management Systems:</strong> We develop robust systems that allow telecom operators to monitor, manage, and optimize their networks in real time, ensuring high uptime and efficient resource utilization.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[1] = el}>
          <p>
          <strong>Billing & OSS/BSS Systems:</strong> Our customized billing, operational support (OSS), and business support (BSS) systems help telecom operators manage subscriber accounts, billing, service provisioning, and customer support with greater efficiency.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[2] = el}>
          <p>
          <strong>Telecom CRM Solutions:</strong> Enhance your customer relationships with our Customer Relationship Management (CRM) solutions designed specifically for the telecom industry. We help you improve customer engagement, streamline communications, and offer personalized services.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[3] = el}>
          <p>
          <strong>5G Solutions:</strong> Prepare for the next generation of connectivity with our 5G software solutions, including network deployment tools, integration systems, and performance optimization platforms.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[4] = el}>
          <p>
          <strong>IoT & M2M Solutions:</strong> As the telecom sector increasingly adopts the Internet of Things (IoT) and Machine-to-Machine (M2M) communication, we offer end-to-end solutions that enable seamless connectivity, data management, and monitoring across devices and networks.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[5] = el}>
          <p>
          <strong>VoIP Solutions:</strong> Our VoIP software solutions offer high-quality voice communication services over the internet, helping telecom companies reduce costs while maintaining excellent call quality.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[6] = el}>
          <p>
          <strong>Cloud-Based Telecom Solutions:</strong> We assist telecom companies in migrating to the cloud, enabling flexible, scalable, and secure operations that reduce infrastructure costs and enhance service delivery.
          </p>
          </div>
        </div>
      </div>

      {/* Recruitment Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>Telecom Recruitment Solutions</h2>
          <p>In the dynamic telecom sector, having access to skilled professionals is essential for staying ahead. Our Telecom Recruitment Solutions are designed to connect you with highly skilled telecom experts who can drive your business forward. Whether you need network engineers, software developers, or telecom project managers, we help you find the right talent.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[7] = el}>
          <p>
          <strong>Telecom Staffing & Placement:</strong> We provide staffing solutions tailored specifically for the telecom industry, ensuring that you find highly skilled professionals for both permanent and contract-based roles.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[8] = el}>
          <p>
          <strong>Executive Search for Telecom Leaders:</strong> Our executive search services are focused on helping you find top-level executives with the experience, vision, and leadership skills required to steer your company toward success.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[9] = el}>
          <p>
          <strong>Specialized Telecom Roles:</strong> We recruit professionals for specialized telecom roles such as network architects, RF engineers, VoIP specialists, OSS/BSS engineers, and more.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[10] = el}>
          <p>
          <strong>Project-Based Recruitment:</strong> For large-scale telecom projects, we provide end-to-end recruitment support, helping you assemble project teams with the technical skills required for successful execution.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[11] = el}>
          <p>
          <strong>Global Telecom Talent Pool:</strong> With a global network of telecom professionals, we help you tap into talent from around the world, offering access to diverse skills and perspectives.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[12] = el}>
          <p>
          <strong>Onboarding & Retention Support:</strong> Our services go beyond recruitment. We assist with onboarding to ensure seamless integration and work with your team to develop retention strategies for top telecom talent.
          </p>
          </div>
        </div>
      </div>

      {/* Technology Training Solutions Section */}
      <div className="solution-section">
        <div className="left-column">
          <h2>Telecom Training Solutions</h2>
          <p>Staying competitive in the telecom industry requires continuous learning and upskilling in emerging technologies. Our Telecom Training Solutions are designed to equip your team with the knowledge and skills needed to excel in today’s fast-evolving telecom landscape. We offer customized training programs for both telecom professionals and organizations.</p>
        </div>
        <div className="right-column">
          <div className="fade-item" ref={el => fadeRef.current[13] = el}>
          <p>
          <strong>Corporate Training Programs for Telecom:</strong> We develop and deliver tailored training programs for telecom companies to upskill their workforce in areas such as network engineering, 5G technology, IoT integration, telecom software systems, and more.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[14] = el}>
          <p>
          <strong>Telecom Engineering Training:</strong> From RF engineering to network architecture and VoIP, our hands-on training modules are designed to equip telecom professionals with the technical expertise needed to manage complex telecom infrastructures.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[15] = el}>
          <p>
          <strong>5G Training & Certification:</strong> As telecom operators transition to 5G networks, we offer comprehensive training programs that cover the essentials of 5G deployment, network management, and security.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[16] = el}>
          <p>
          <strong>IoT & M2M Training:</strong> Learn how to design, implement, and manage IoT networks with our specialized IoT and M2M training solutions. Our programs cover both the technical and business aspects of IoT deployments in telecom.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[17] = el}>
          <p>
          <strong>Telecom Project Management Training:</strong> Equip your team with the skills needed to successfully manage large-scale telecom projects, from planning and resource management to execution and delivery.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[18] = el}>
          <p>
          <strong>Certification Preparation:</strong> Prepare your telecom professionals for industry-recognized certifications in areas like 5G, networking, VoIP, cloud telecom solutions, and more.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[19] = el}>
          <p>
          <strong>Hybrid Learning Options:</strong> Our flexible training delivery methods include online, in-person, and hybrid options to accommodate your team's learning preferences and schedule.
          </p>
          </div>
          <div className="fade-item" ref={el => fadeRef.current[20] = el}>
          <p>
          <strong>Ongoing Learning & Development:</strong> Beyond one-off courses, we offer continuous learning programs to help your team stay updated with the latest trends, tools, and best practices in the telecom industry.
          </p>
          </div>
        </div>
      </div>
    </div>
    <div className='more-space'>
    <Contact />
    </div>
    </Fragment>
    
    
  );
};

export default Telecommunication;
