import React from 'react';
import '../Styles/Contact.css';

function Contact() {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>  
      <div className="contact-info">
        <div className="contact-item">
          <h3>Email</h3>
          <p><a href="mailto:business@saffroot.com">business@saffroot.com</a></p>
        </div>
        <div className="contact-item">
          <h3>Phone</h3>
          <p><a href="tel:">6212216329</a></p>
        </div>
      </div>

      <div className="social-links">
        <a href="https://www.facebook.com/p/Saffroot-100069939177020/" target="_blank" rel="noopener noreferrer">Facebook</a>
        <a href="https://www.linkedin.com/company/saffroot-technologies/mycompany/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </div>

      {/* Footer with copyright */}
      <footer className="footer">
        <p>&copy; {currentYear} Saffroot. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Contact;
