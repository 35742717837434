import React from 'react';
import '../Styles/IndustriesRibbon.css'; // Assuming you have the CSS saved in this file
import { Link } from 'react-router-dom';
import Contact from './Contact';

const IndustriesRibbon = () => {
  return (
    <div className="industry-solutions">
    <section className="industry-title">
    <h1>Empowering Key Industries</h1>
    <p>
    At <strong>Saffroot Technologies</strong>, we are committed to empowering organizations across multiple industries 
    with innovative solutions that enhance operational efficiency, drive growth, and 
    improve service delivery. Our tailored Software Solutions, Recruitment Services, and 
    Training Programs are designed to meet the unique challenges of each sector we serve.
    </p>
  </section>

      {/* Information Technology */}
      <div className="industry-section">
        <h2>Information Technology</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We provide end-to-end software development, covering cloud computing, project management systems, and cutting-edge platforms for digital transformation. From custom-built enterprise solutions to cloud-based SaaS platforms, our offerings are designed to enhance productivity and collaboration, streamline workflows, and secure your IT infrastructure.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>Our IT recruitment experts help source top-tier talent, including software engineers, data scientists, DevOps professionals, and IT project managers. Whether you need a temporary consultant or a permanent hire, we connect you with individuals who can help drive your digital transformation initiatives.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our IT training programs encompass everything from software development and cybersecurity to agile methodologies and cloud computing. We ensure your team is equipped with the skills needed to stay ahead in this rapidly evolving industry.</p>
          </div>
        </div>
        <Link to="/ind-it" className="learn-more-button">Learn More</Link>
      </div>

      {/* Telecommunications */}
      <div className="industry-section">
        <h2>Telecommunications</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We provide software solutions designed to enhance network management, streamline billing systems, and optimize customer service in the telecommunications industry. Whether it's real-time billing software, network monitoring tools, or customer relationship management systems, our solutions support efficiency and scalability.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>Our telecom recruitment services focus on sourcing network engineers, technicians, and sales professionals. We specialize in finding talent that understands the latest telecom technologies, including 5G, IoT, and cloud-based communication systems.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>We offer specialized training programs in network management, customer service, and telecom technologies. Our courses help your staff master the skills needed to implement and manage new telecom systems and ensure seamless customer experiences.</p>
          </div>
        </div>
        <Link to="/ind-telcom" className="learn-more-button">Learn More</Link>
      </div>

      {/* Semiconductor & VLSI */}
      <div className="industry-section">
        <h2>Semiconductor & VLSI</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We offer comprehensive software solutions for the semiconductor and VLSI industry, including design automation tools, simulation software, and process control systems. Our software supports every stage of the semiconductor lifecycle, from design to manufacturing, ensuring high precision and quality.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>Our recruitment services focus on sourcing design engineers, manufacturing specialists, and process engineers with expertise in semiconductor technology. We connect you with the talent that can help drive innovation in chip design and semiconductor manufacturing.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our training programs cover design tools, manufacturing processes, and quality assurance in the semiconductor industry. We help your team stay updated on the latest VLSI and semiconductor technologies, ensuring they are equipped to meet industry demands.</p>
          </div>
        </div>
        <Link to="/ind-vlsi" className="learn-more-button">Learn More</Link>
      </div>

      {/* Banking, Financial Services, and Insurance (BFSI) */}
      <div className="industry-section">
        <h2>Banking, Financial Services and Insurance (BFSI)</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We provide software solutions for core banking, wealth management, risk management, and regulatory compliance. From payment gateways to fraud detection systems, our solutions enhance the security and efficiency of financial services, helping you meet customer expectations and regulatory standards.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We help financial institutions source talent such as financial analysts, compliance officers, and risk managers who are familiar with the latest fintech innovations and regulatory requirements. Our recruitment solutions ensure that you find professionals who can drive success in your financial operations.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our training programs in BFSI cover compliance, risk management, financial analysis, and customer relationship management. We ensure that your team understands the latest regulatory requirements, financial technologies, and risk management strategies.</p>
          </div>
        </div>
        <Link to="/ind-bfsi" className="learn-more-button">Learn More</Link>
      </div>

      {/* Oil & Gas */}
      <div className="industry-section">
        <h2>Oil & Gas</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We provide software solutions that optimize exploration, production, and supply chain management in the oil and gas industry. From asset management systems to predictive maintenance software, our solutions enhance operational efficiency and safety.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We help oil and gas companies recruit geologists, engineers, and project managers with experience in exploration, production, and safety management. Our recruitment services ensure that you have the right talent to manage complex operations and drive innovation.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our training programs cover safety management, environmental sustainability, and operational efficiency in the oil and gas sector. We provide specialized courses on drilling techniques, compliance, and energy management, ensuring your workforce is equipped to handle industry challenges.</p>
          </div>
        </div>
        <Link to="/ind-ong" className="learn-more-button">Learn More</Link>
      </div>

      {/* Automotive */}
      <div className="industry-section">
        <h2>Automotive</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We offer a range of software solutions for vehicle diagnostics, manufacturing automation, and connected vehicle technology. Our solutions help automotive companies enhance safety, improve manufacturing processes, and develop smarter, more connected vehicles.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We assist in recruiting automotive engineers, quality control specialists, and supply chain experts who can contribute to innovation in automotive technology. Our recruitment services ensure you have the skilled professionals needed to keep pace with the rapidly evolving automotive landscape.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our training programs cover vehicle safety standards, automotive manufacturing processes, and the latest advancements in automotive technology. We help your workforce stay current with industry trends and regulations, ensuring top-quality production and safety compliance.</p>
          </div>
        </div>
        <Link to="/ind-auto" className="learn-more-button">Learn More</Link>
      </div>

      {/* Manufacturing */}
      <div className="industry-section">
        <h2>Manufacturing</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We provide comprehensive manufacturing software solutions, including ERP systems, inventory management tools, and quality control platforms. Our software helps manufacturers streamline production processes, optimize inventory, and reduce waste.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We help manufacturing companies source production managers, quality assurance specialists, and engineers who can drive process improvements and ensure product quality. Our recruitment solutions focus on finding talent that understands lean manufacturing, automation, and industry regulations.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our training solutions for the manufacturing industry include lean manufacturing principles, quality control, and workplace safety management. We ensure that your team is equipped with the skills to increase efficiency and maintain compliance with industry standards.</p>
          </div>
        </div>
        <Link to="/ind-man" className="learn-more-button">Learn More</Link>
      </div>

      {/* Embedded Systems */}
      <div className="industry-section">
        <h2>Embedded Systems</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>Our embedded software solutions support device development and integration for a wide range of applications. Whether it's for industrial automation, consumer electronics, or medical devices, our software enhances the functionality, reliability, and performance of embedded systems.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We specialize in sourcing embedded software engineers, hardware designers, and systems architects who have expertise in embedded technology. Our recruitment services ensure you find professionals who can develop and integrate cutting-edge embedded systems.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our embedded systems training covers embedded software development, real-time operating systems, and hardware integration. We help your team stay updated on the latest advancements in embedded technologies, ensuring successful product development and deployment.</p>
          </div>
        </div>
        <Link to="/ind-embd" className="learn-more-button">Learn More</Link>
      </div>

      {/* Energy */}
      <div className="industry-section">
        <h2>Energy</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We provide energy management software that optimizes resource utilization, supports renewable energy integration, and enhances grid management. Our solutions help energy companies achieve sustainability goals and improve operational efficiency.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We assist energy companies in sourcing renewable energy specialists, project managers, and energy analysts. Our recruitment services focus on finding professionals who can help drive innovation and efficiency in the energy sector.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our training programs focus on energy management, renewable energy technologies, and regulatory compliance. We ensure your workforce has the knowledge to navigate the complexities of the energy industry and support the transition to sustainable practices.</p>
          </div>
        </div>
        <Link to="/ind-ene" className="learn-more-button">Learn More</Link>
      </div>

      {/* Government */}
      <div className="industry-section">
        <h2>Government</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category one-cat">
            <h3>Software Solutions</h3>
            <p>We offer software solutions for public administration, e-governance, and citizen engagement. Our solutions help government agencies improve transparency, enhance service delivery, and streamline public services through digital transformation.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>Our recruitment services specialize in finding public administration professionals, policy analysts, and IT specialists for government roles. We ensure you find skilled talent that can support the efficient operation and digitalization of government services.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our government training programs cover public administration, policy development, and digital governance. We help government officials and staff acquire the skills needed to implement innovative policies and improve service delivery.</p>
          </div>
        </div>
        <Link to="/ind-gov" className="learn-more-button">Learn More</Link>
      </div>

      {/* Pharmaceuticals */}
      <div className="industry-section">
        <h2>Pharmaceuticals</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category">
            <h3>Software Solutions</h3>
            <p>We provide software for clinical trials, regulatory compliance, and patient engagement, supporting innovation and quality in the pharmaceutical industry. Our solutions streamline drug development, ensure compliance with regulations, and enhance patient care.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We help pharmaceutical companies recruit scientists, regulatory affairs specialists, and quality assurance professionals. Our recruitment services ensure you find talent with the expertise needed to support research, development, and compliance.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our training programs cover good clinical practice, regulatory compliance, and pharmacovigilance. We ensure your team understands the latest industry regulations and practices, helping maintain high standards in drug development and patient safety.</p>
          </div>
        </div>
        <Link to="/ind-pharma" className="learn-more-button">Learn More</Link>
      </div>

      {/* Healthcare */}
      <div className="industry-section">
        <h2>Healthcare</h2>
        <div className="industry-categories">

          {/* Software Solutions */}
          <div className="industry-category">
            <h3>Software Solutions</h3>
            <p>We offer EHR systems, telemedicine platforms, and analytics solutions to enhance patient care and operational efficiency in healthcare organizations. Our solutions ensure healthcare providers can offer better patient outcomes through data-driven decision-making and seamless patient interaction.</p>
          </div>

          {/* Recruitment Solutions */}
          <div className="industry-category">
            <h3>Recruitment Solutions</h3>
            <p>We assist healthcare organizations in finding physicians, nurses, allied health professionals, and healthcare administrators. Our recruitment services focus on connecting healthcare providers with professionals who are committed to delivering high-quality patient care.</p>
          </div>

          {/* Training Solutions */}
          <div className="industry-category">
            <h3>Training Solutions</h3>
            <p>Our healthcare training programs cover clinical skills, patient safety, and telehealth practices. We ensure healthcare professionals are equipped with the latest knowledge to provide excellent care in an evolving healthcare landscape.</p>
          </div>
        </div>
        <Link to="/ind-health" className="learn-more-button">Learn More</Link>
      </div>

      {/* Add more industries as needed */}
      <div className='contact-two'>
      <Contact />
      </div>
     
    </div>
  );
};

export default IndustriesRibbon;
