import React, { useState, useEffect, useRef } from 'react';
import '../Styles/Industries.css';
import image1 from '../images/ind-it-steering-4864047.jpg';
import image2 from '../images/ind-vlsi-semiconductur-ai-generated-8785381.jpg';
import image3 from '../images/ind-embedded-circuit-board-5936930.jpg';
import image4 from '../images/ind-automotive2-ai-generated-8894141.jpg';
import image5 from '../images/ind-bfsi-finance-8154775.jpg';
import image6 from '../images/ind-manufacturing2-ai-generated-9055481.jpg';
import image7 from '../images/ind-telecom-telecom-pexels-robertkso-16244235.jpg';
import image8 from '../images/ind-energy-energy-7669088.jpg';
import image9 from '../images/ind-ong-pexels-pixabay-257700.jpg';
import image10 from '../images/ind-technology-technology-7042241_1280.jpg';
import image11 from '../images/ind-government-interior-design-828545_1920.jpg';
import image12 from '../images/ind-healthcare-doctor-6676747.jpg';
import image13 from '../images/ind-pharmaceuticals-pills-3673645.jpg';

const Industries = () => {
  const industries = [
    { name: "Information Technology (IT)", link: "/ind-it", image: image1 },
    { name: "Semiconductor And Very Large Scale Integration (VLSI)", link: "/ind-vlsi", image: image2 },
    { name: "Embedded Systems", link: "/ind-embd", image: image3 },
    { name: "Automotive", link: "/ind-auto", image: image4 },
    { name: "Banking, Financial Services, and Insurance (BFSI)", link: "/ind-bfsi", image: image5 },
    { name: "Manufacturing", link: "/ind-man", image: image6 },
    { name: "Telecom", link: "/ind-telcom", image: image7 },
    { name: "Energy", link: "/ind-ene", image: image8 },
    { name: "Oil & Gas", link: "/ind-ong", image: image9 },
    { name: "Government", link: "/government", image: image11 },
    { name: "Healthcare", link: "/healthcare", image: image12 },
    { name: "Pharmaceuticals", link: "/pharmaceuticals", image: image13 },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [headingVisible, setHeadingVisible] = useState(false);
  const headingRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % industries.length);
    }, 3000); // Change every 3 seconds
    return () => clearInterval(interval);
  }, [industries.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHeadingVisible(true);
        } else {
          setHeadingVisible(false); // Optional: Reset visibility when scrolled out
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the heading is visible
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);

  return (
    <div className="part full-width-background3">
      <div className="new-section-content3">
        <h2
          className={`industries-heading ${headingVisible ? 'visible' : ''}`}
          ref={headingRef}
        >
          Industries We Serve
        </h2>
        <div className="industries-list">
          {industries.map((industry, index) => (
            <a
              key={index}
              href={industry.link}
              className={`industry-image-container ${activeIndex === index ? 'active' : ''}`}
            >
              <img src={industry.image} alt={industry.name} className="industry-image" />
              <div className="industry-name-overlay">{industry.name}</div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Industries;
