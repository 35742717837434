import React, { useState } from 'react';
import '../Styles/JobSeekerContact.css';

const ClientContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    currentCompany: '',
    currentDesignation: '',
    typeOfSolution: [],
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (value) => {
    setFormData((prevData) => {
      const isSelected = prevData.typeOfSolution.includes(value);
      const updatedSolutions = isSelected
        ? prevData.typeOfSolution.filter((solution) => solution !== value)
        : [...prevData.typeOfSolution, value];
      return {
        ...prevData,
        typeOfSolution: updatedSolutions,
      };
    });
  };

  const validate = () => {
    let validationErrors = {};
    if (!formData.name.trim()) validationErrors.name = 'Name is required';
    if (!formData.email.trim()) validationErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) validationErrors.email = 'Email address is invalid';
    if (!formData.phone.trim()) validationErrors.phone = 'Phone number is required';
    if (!formData.currentCompany.trim()) validationErrors.currentCompany = 'Company name is required';
    if (!formData.currentDesignation.trim()) validationErrors.currentDesignation = 'Designation is required';
    if (formData.typeOfSolution.length === 0) validationErrors.typeOfSolution = 'Please select at least one solution type';
    if (!formData.message.trim()) validationErrors.message = 'Message is required';
    return validationErrors;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
  
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch('https://saffroot.com/api/client-contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          setSubmitted(true);
          setFormData({
            name: '',
            email: '',
            phone: '',
            currentCompany: '',
            currentDesignation: '',
            typeOfSolution: [],
            message: '',
          });
          setErrors({});
        } else {
          console.error('Failed to submit form');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="contact-form-container">
      <h2>Partner with Us – Get in Touch</h2>
      {submitted && <p className="success-message">Thank you! We’ll be in touch soon.</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your name"
          />
          {errors.name && <p className="error">{errors.name}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your email"
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your phone number"
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="currentCompany">Current Company</label>
          <input
            type="text"
            name="currentCompany"
            value={formData.currentCompany}
            onChange={handleChange}
            placeholder="Your current company"
          />
          {errors.currentCompany && <p className="error">{errors.currentCompany}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="currentDesignation">Designation</label>
          <input
            type="text"
            name="currentDesignation"
            value={formData.currentDesignation}
            onChange={handleChange}
            placeholder="Your Current designation at the organization"
          />
          {errors.currentDesignation && <p className="error">{errors.currentDesignation}</p>}
        </div>
        <div className="form-group">
          <label>Type of Solution You Are Seeking</label>
          <div className="multi-select-container">
            <div
              className="multi-select-input"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              {formData.typeOfSolution.length > 0
                ? formData.typeOfSolution.join(', ')
                : 'Select solutions...'}
            </div>
            {showDropdown && (
              <div className="multi-select-dropdown">
                <label>
                  <input
                    type="checkbox"
                    checked={formData.typeOfSolution.includes('Software Solutions')}
                    onChange={() => handleCheckboxChange('Software Solutions')}
                  />
                  Software Solutions
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.typeOfSolution.includes('Recruitment Solutions')}
                    onChange={() => handleCheckboxChange('Recruitment Solutions')}
                  />
                  Recruitment Solutions
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.typeOfSolution.includes('Technology Training Solutions')}
                    onChange={() => handleCheckboxChange('Technology Training Solutions')}
                  />
                  Technology Training Solutions
                </label>
              </div>
            )}
          </div>
          {errors.typeOfSolution && <p className="error">{errors.typeOfSolution}</p>}
        </div>
        <div className="form-group">
      <label htmlFor="message">Message</label>
      <textarea
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder="Your message"
        rows="4"
      />
      {errors.message && <p className="error">{errors.message}</p>}
    </div>
        <button type="submit" className="contact-button">Submit</button>
      </form>
    </div>
  );
};

export default ClientContact;
