import React, { useState, useEffect } from 'react';
import '../Styles/Home.css'; 
import image1 from '../images/Img1.png';
import image3 from '../images/Image3.png';
import image4 from '../images/Image4.png';
import Industries from './Industries';
import Technologies from './Technologies';
import training from '../images/sw train.png'
import rec2 from '../images/sw hir.png';
import image2 from '../images/Image2.png';
import softdev from '../images/sw dev.png';
import Contact from './Contact';

function Home() {

  const images = [image1, image2, image3, image4];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);

  const goToPreviousImage = () => {
    setCurrentImageIndex(prevIndex => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const secondPart = document.querySelector('.full-width-background2');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            secondPart.classList.add('animate-images');
          } else {
            secondPart.classList.remove('animate-images');
          }
        });
      },
      { threshold: 0.3 }
    );

    observer.observe(secondPart);

    return () => {
      if (secondPart) {
        observer.unobserve(secondPart);
      }
    };
  }, []);

  return (
    <div className="main-home-container">
      {/* First Part */}
      <div className="part full-width-background1">
        <div className="carousel">
          <button className="left-arrow" onClick={goToPreviousImage}>&lt;</button>
          <img
            src={images[currentImageIndex]}
            alt={`Slide ${currentImageIndex + 1}`}
            className="carousel-image"
          />
          <button className="right-arrow" onClick={goToNextImage}>&gt;</button>
          <div className="dots">
            {images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                onClick={() => goToImage(index)}
              ></span>
            ))}
          </div>
        </div>
       {/*

<div className="quote-overlay">
          {quotes[currentImageIndex]}
        </div>
        <img src={logo} alt="Logo" className="logo-in-background" />
       */} 
      </div>

    {/* Second Part */}
    <div className="part full-width-background2">
  <div className="header-row">
    <h2>Our Services</h2>
    <a href="/application-development" className="image-link">
      <img src={softdev} alt="Software Solutions" />
      <span className="image-text">Software Solutions</span>
    </a>
  </div>
  <div className="image-container">
    <a href="/recruitment-solutions" className="image-link one">
      <img src={rec2} alt="Recruitment Solutions" />
      <span className="image-text">Recruitment Solutions</span>
    </a>
    <a href="/training-section" className="image-link two">
      <img src={training} alt="Tech Training Solutions" />
      <span className="image-text">Tech Training Solutions</span>
    </a>
  </div>
  <div className="background-overlay"></div>
</div>


    
      {/* Third Part */}
      <Industries />

      {/* Fourth Part */}

      <Technologies />
      <Contact />
    </div>
  );
}

export default Home;
