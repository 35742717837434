import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home.js';
import About from './components/About.js';
import Services from './components/Services.js';
import Ribbon from './components/Ribbon';
import ScrollToTop from './components/ScrollToTop.js';
import SoftwareDevelopment from './components/SoftwareDevelopment.js'


import RecruitmentSolutions from './components/RecruitmentSolutions.js';
import TrainingSection from './components/TrainingSection.js';
import IndustriesRibbon from './components/IndustriesRibbon.js';
import InformationTechnology from './components/InformationTechnology.js';
import Telecommunication from './components/Telecommunication.js';
import Bfsi from './components/Bfsi.js';
import Vlsi from './components/Vlsi.js';
import Manufacturing from './components/Manufacturing.js';
import Automotive from './components/Automotive.js';
import Oilandgas from './components/Oilandgas.js';
import Government from './components/Government.js';
import Pharmaceuticals from './components/Pharmaceuticals.js';
import Healthcare from './components/Healthcare.js';
import Embedded from './components/Embedded.js';
import Energy from './components/Energy.js';
import JobSeekerContact from './components/JobSeekerContact.js';
import ClientContact from './components/ClientContact.js';

function App() {
  return (
      <div>
      <ScrollToTop />
        <Ribbon />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/client-contact" element={<ClientContact />} />
          <Route path="/application-development" element={<SoftwareDevelopment />} />
          <Route path="/recruitment-solutions" element={<RecruitmentSolutions />} />
          <Route path="/training-section" element={<TrainingSection />} />
          <Route path="/industries-ribbon" element={<IndustriesRibbon />} />
          <Route path="/ind-it" element={<InformationTechnology />} />
          <Route path="/ind-telcom" element={<Telecommunication />} />
          <Route path="/ind-bfsi" element={<Bfsi />} />
          <Route path="/ind-Man" element={<Manufacturing />} />
          <Route path="/ind-Auto" element={<Automotive />} />
          <Route path="/ind-vlsi" element={<Vlsi />} />
          <Route path="/ind-embd" element={<Embedded />} />
          <Route path="/ind-gov" element={<Government />} />
          <Route path="/ind-ene" element={<Energy />} />
          <Route path="/ind-health" element={<Healthcare />} />
          <Route path="/ind-pharma" element={<Pharmaceuticals />} />
          <Route path="/ind-ong" element={<Oilandgas />} />
          <Route path="/career" element={<JobSeekerContact />} />

        </Routes>
      </div>
  );
}

export default App;