import React, { useEffect, useRef } from 'react';
import '../Styles/AllService.css'
import Contact from '../components/Contact';

const TrainingSection = () => {

  const fadeRef = useRef([]);

  useEffect(() => {
    const handleFadeIn = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        } else {
          entry.target.classList.remove('fade-in');
        }
      });
    };

    const observer = new IntersectionObserver(handleFadeIn, {
      threshold: 0.3,
    });

    fadeRef.current.forEach(fadeItem => {
      if (fadeItem) observer.observe(fadeItem);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="all-service-container">
      <section className="all-service-hero">
        <h1>Technology Training Solutions</h1>
        <p>
          At <strong>Saffroot Technologies</strong>, we are committed to equipping both professionals and students with the skills they need to excel in today’s fast-paced technological world. Our comprehensive technology training programs are designed to foster growth, drive innovation, and meet the ever-evolving demands of the tech industry.
        </p>
      </section>

      <section className="all-service-details">
        <div className="all-service-details-box">
          <h2>What We Offer:</h2>
          <div className="fade-div" ref={el => fadeRef.current[0] = el}>
          <p>
          <strong>Corporate Training:</strong> We partner with organizations to provide specialized training programs that focus on upskilling employees, helping businesses stay competitive in the digital age. Our corporate training modules are designed to address the specific technological needs of companies, from entry-level employees to senior-level experts.
        </p>
          </div>

          <div className="fade-div" ref={el => fadeRef.current[1] = el}>
          <p>
            <strong>Skill Development for Colleges:</strong> We work closely with educational institutions to prepare students for successful careers in technology. Our curriculum is aligned with industry standards and focuses on practical skills and project-based learning, ensuring students are ready to meet real-world challenges.
          </p>
          </div>
          
        </div>

        <div className="all-service-details-box">
          <h2>Areas of Expertise:</h2>
          <div className="fade-div" ref={el => fadeRef.current[2] = el}>
          <p>
          <strong>Artificial Intelligence (AI) & Machine Learning (ML):</strong> Dive deep into AI/ML concepts, frameworks, and applications. Learn how to develop intelligent systems using the latest tools like TensorFlow, PyTorch, and more.
        </p>
          </div>
        
          <div className="fade-div" ref={el => fadeRef.current[3] = el}>
          <p>
          <strong>Cybersecurity:</strong> With the rise in digital threats, our cybersecurity training provides in-depth knowledge of network security, data protection, and ethical hacking, equipping learners to safeguard systems and sensitive information.
        </p>
          </div>
        
          <div className="fade-div" ref={el => fadeRef.current[4] = el}>
          <p>
          <strong>Software Development:</strong> Master the art of software engineering by learning programming languages such as Java, Python, C++, and modern development frameworks like React, Angular, and Node.js. Our courses also cover best practices in software design, testing, and deployment.
        </p>
          </div>
         
          <div className="fade-div" ref={el => fadeRef.current[5] = el}>
          <p>
          <strong>Embedded Systems & VLSI Design:</strong> Gain expertise in embedded technologies that power today’s electronics and smart devices. We offer hands-on training in microcontroller programming, real-time operating systems, and hardware-software integration. Additionally, our VLSI courses cover advanced chip design, simulation, and verification techniques.
        </p>
          </div>
        
          <div className="fade-div" ref={el => fadeRef.current[6] = el}>
          <p>
            <strong>Cloud Computing & DevOps:</strong> Learn how to build, manage, and scale applications in cloud environments using platforms like AWS, Azure, and Google Cloud. Our DevOps training also covers CI/CD, containerization, and automation tools like Docker, Kubernetes, and Jenkins.
          </p>
          </div>
          
          <div className="fade-div" ref={el => fadeRef.current[7] = el}>
          <p>
          <strong>Data Science & Big Data:</strong> Understand the core concepts of data analysis, machine learning, and big data technologies. Learn how to work with tools like Hadoop, Spark, and Apache Kafka to process and analyze large datasets.
        </p>
          </div>
        
        </div>

      <div className="all-service-details-box">
          <h2>Why Train with Saffroot?</h2>
          <div className="fade-div" ref={el => fadeRef.current[8] = el}>
          <p>
          <strong>Expert-Led Courses: </strong> Our training programs are delivered by industry experts with years of experience in leading tech companies. Their practical insights and real-world knowledge ensure participants gain a deep understanding of the subject matter.
        </p>
          </div>
       
          <div className="fade-div" ref={el => fadeRef.current[9] = el}>
          <p>
          <strong>Customized Learning Paths:</strong> We believe in flexibility and offer tailored learning paths based on your organization's or institution's specific needs. Whether it’s a short-term bootcamp or a long-term upskilling program, we ensure the curriculum is aligned with your goals.
        </p>
          </div>
        
          <div className="fade-div" ref={el => fadeRef.current[10] = el}>
          <p>
          <strong>Hands-On Projects & Real-World Applications: </strong> Our courses emphasize practical learning through real-world projects, case studies, and interactive sessions. Participants not only learn the theory but also apply their knowledge to solve real industry problems.
        </p>
          </div>
        
        <div className="fade-div" ref={el => fadeRef.current[11] = el}>
        <p>
        <strong>Innovative Learning Tools:</strong> At Saffroot, we use the latest in digital learning platforms, incorporating virtual labs, online resources, and simulation tools to enhance the learning experience.
      </p>
        </div>
    
  
        </div>
        
        <div className="all-service-details-box">
          <h2>Our Training Process:</h2>
          <div className="fade-div" ref={el => fadeRef.current[12] = el}>
          <p>
          <strong>Initial Assessment: </strong> We begin by understanding the specific needs and skill gaps within your team or student body
        </p>
          </div>
         
          <div className="fade-div" ref={el => fadeRef.current[13] = el}>
          <p>
          <strong>Customized Curriculum:</strong> Based on the assessment, we design a training program that’s tailored to your requirements.
        </p>
          </div>
         
          <div className="fade-div" ref={el => fadeRef.current[14] = el}>
          <p>
          <strong>Interactive Training: </strong> Our sessions are engaging and interactive, blending theory with hands-on practice.
        </p>
          </div>
          
        <div className="fade-div" ref={el => fadeRef.current[15] = el}>
        <p>
        <strong>Performance Tracking: </strong> We monitor participant progress through quizzes, assignments, and project work.
      </p>
        </div>
     
        <div className="fade-div" ref={el => fadeRef.current[16] = el}>
        <p>
        <strong>Post-Training Support:</strong> We offer post-training assistance to ensure that the participants are able to apply their newfound skills effectively in their work environment.
      </p>
        </div>
       
        </div>
       
        <div className="all-service-details-box additional-space">
        <h2>Who Can Benefit from Our Programs?</h2>
        <div className="fade-div" ref={el => fadeRef.current[17] = el}>
        <p>
        <strong>Corporate Employees:</strong> From IT professionals to senior management, our courses are designed to help employees across all levels enhance their technological expertise and stay ahead of industry trends.
      </p>
        </div>
       
        <div className="fade-div" ref={el => fadeRef.current[18] = el}>
        <p>
        <strong>Students:</strong> We offer specialized programs for MTech, BTech, and other technical degree students, preparing them for the demands of the tech industry with hands-on training and industry-relevant skills.
      </p>
        </div>
       
      </div>
      </section>

      <Contact />
    </div>
  );
};

export default TrainingSection;
